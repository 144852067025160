import { TinyColor } from '@ctrl/tinycolor';
import { css } from '@emotion/react';

import {
  tabletBreakpointRem,
  desktopBreakpointRem,
  breakpoints,
  gridMaxWidth,
  gridPadding,
} from './theme';

/**
 * **Fluid Typography**
 *
 * A form of responsive resizing of font-size that creates a smooth scaling.
 * Adapted from:
 * [CSS Tricks: Fluid Typography](https://css-tricks.com/snippets/css/fluid-typography/).
 *
 * @example fluidTypography(3.2, 4.8); // from 32px to 48px
 *
 * @param {number} minFontSize Minimum font-size (in REM).
 * @param {number} maxFontSize Maximum font-size (in REM).
 */
export const fluidTypography = (minFontSize, maxFontSize) => css`
  ${mobileOnly} {
    font-size: ${minFontSize}rem;
  }

  ${tabletUp} {
    font-size: calc(
      ${minFontSize}rem + (${maxFontSize - minFontSize}) *
        (
          (100vw - ${tabletBreakpointRem}rem) /
            (${desktopBreakpointRem} - ${tabletBreakpointRem})
        )
    );
  }

  ${desktopUp} {
    font-size: ${maxFontSize}rem;
  }
`;

/**
 * Media Query helpers
 * See usage examples in `docs/styling.md`
 */

export const mediaUp = screenSize => `@media (min-width: ${screenSize})`;
export const mediaDown = screenSize => `@media (max-width: ${screenSize})`;
export const mediaBetween = (minScreenSize, maxScreenSize) =>
  `@media (min-width: ${minScreenSize}) and (max-width: ${maxScreenSize})`;
/** CSS media query for anything below tablet screen. */
export const mobileOnly = mediaDown(`${breakpoints.tablet - 1}px`);
export const mobileOnlyPortrait = `${mobileOnly} and (orientation: portrait)`;
export const mobileOnlyLandscape = `@media (max-height: ${
  breakpoints.tablet - 1
}px) and (orientation: landscape)`;
/** CSS media query for smallest tablet screen and above. */
export const tabletUp = mediaUp(breakpoints.tabletPx);
/** CSS media query for tablet only. */
export const tabletOnly = mediaBetween(
  breakpoints.tabletPx,
  `${breakpoints.desktop - 1}px`
);
/** CSS media query for smallest desktop screen and above. */
export const desktopUp = mediaUp(breakpoints.desktopPx);
/** CSS media query for screens that go above theme's grid. */
export const gridUp = `@media (min-width: ${gridMaxWidth + gridPadding * 2}px)`;
/** CSS media query for screens that go between tablet and our grid width. */
export const tabletToGrid = mediaBetween(
  breakpoints.tabletPx,
  `${gridMaxWidth + gridPadding * 2}px`
);

/**
 * Selectors helpers
 */

export const notLast = `&:not(:last-of-type)`;
export const notFirst = `&:not(:first-of-type)`;

/**
 * Color helpers
 */

/**
 * Sets the alpha value on a current color and returns it as an RGBA string.
 *
 * @param {string} color Hexadecimal representation of color
 * @param {number} opacity Opacity level (from 0 to 1)
 */
export function setOpacity(color, opacity) {
  return new TinyColor(color).setAlpha(opacity).toRgbString();
}

/**
 * Adding measurements to make a target mobile-friendly and easier to tap.
 *
 * @see
 * [Tap targets are not sized appropriately](https://developers.google.com/web/tools/lighthouse/audits/tap-targets)
 */
export const tapTargetCss = css`
  ${mobileOnly} {
    min-height: 48px;
    min-width: 48px;
  }
`;

/**
 * Truncate text.
 *
 * @param {number} lineClamp The number of lines to clamp
 */
export const truncateTextCss = lineClamp => css`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lineClamp};
`;

/**
 * Apply styles when a user's device allows hovering and has an accurate
 * pointing device such a mouse.
 *
 * Useful when hovering styles aren't needed for mobile.
 *
 * @see
 * [hover](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover),
 * [pointer](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer)
 */
export const hoverAvailable = '@media (hover: hover) and (pointer: fine)';

/**
 * Format text with style markups within it.
 *
 * Visual style markups -
 * - Use * for bold text (*example*)
 * - Use _ for italicized text (_italic_)
 * - Use ~ for strikethrough text (~exmaple~)
 *
 * @param {string} text
 */
export const formatText = text => {
  const bold = /\*(.*?)\*/gm;
  const italic = /_(.*?)_/gm;
  const strikethrough = /~(.*?)~/gm;
  const space = / /g;

  const formatted = text
    .replace(strikethrough, '<del>$1</del>')
    .replace(italic, '<i>$1</i>')
    .replace(bold, '<b>$1</b>')
    // NOTE: Replacing spaces because they're not showing on JSX
    .replace(space, '&nbsp;');

  return formatted;
};
